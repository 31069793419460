import { type FC, type Children, type ReactElement, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import dibsCss from 'dibs-css';
import { FormattedMessage } from 'dibs-react-intl';
import { type HeadingType } from 'dibs-controlled-heading/exports/HeadingLevel';

import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
import { FILTER_SEARCH_TYPE, SEARCH_TYPES } from '../../constants/sbConstants';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { getFilterValueByUrlLabel } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { useSharedSaveSearchContext } from '../../shared/SharedSaveSearchContext/SharedSaveSearchContext';
import { useSbSelector } from '../../reducers/useSbSelector';
import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';

import { type SbSharedTitle_itemSearch$key } from './__generated__/SbSharedTitle_itemSearch.graphql';
import { type SbSharedTitle_user$key } from './__generated__/SbSharedTitle_user.graphql';

import styles from './SbSharedTitle.scss';

const { SEARCH, DESIGN, DEALER } = pageTypes;
const { FUZZY } = SEARCH_TYPES;

const itemSearchFragment = graphql`
    fragment SbSharedTitle_itemSearch on ItemSearchQueryConnection {
        pageType
        searchTerm
        totalResults
        searchCorrections {
            originalSearchTerm
        }
        meta {
            header
            subHeader
        }
        appliedFilters {
            name
            values {
                urlLabel
            }
        }
        soldItems: soldResults(first: 28) {
            totalResults
        }
        seller {
            ...sellerBrandingHelpers_seller
        }
    }
`;

const userFragment = graphql`
    fragment SbSharedTitle_user on User {
        ...sellerBrandingHelpers_user
    }
`;

type Props = {
    itemSearch: SbSharedTitle_itemSearch$key;
    user: SbSharedTitle_user$key;
    Heading?: HeadingType;
    showContentModule?: boolean;
    children?: typeof Children;
    headerWrapperClass?: string;
};

export const SbSharedTitle: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
    Heading,
    showContentModule = false,
    children,
    headerWrapperClass,
}) => {
    const { showFollowButton } = useSharedSaveSearchContext();
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);
    const isClient = useSbSelector(state => state.relayVariables.variables.isClient);
    const { isMobile } = useServerVarsContext();

    const {
        meta,
        pageType,
        appliedFilters,
        searchCorrections,
        searchTerm,
        soldItems,
        totalResults,
        seller,
    } = itemSearch;
    const { header, subHeader } = meta || {};

    const isFuzzyResults = !!getFilterValueByUrlLabel(appliedFilters, FILTER_SEARCH_TYPE, FUZZY);
    const showTitleFollowButton = showFollowButton && !isFuzzyResults;
    const hasSpellCorrection = searchCorrections?.originalSearchTerm;
    const HeadingTag = pageType === DESIGN && showContentModule ? 'h2' : 'h1';

    let customHeader: ReactNode;
    if (hasSpellCorrection) {
        customHeader = (
            <FormattedMessage
                id="sb.SbSharedTitle.spellTitle"
                defaultMessage="Showing results for"
            />
        );
    } else if (isMobile) {
        customHeader = (
            <FormattedMessage id="sb.SbSharedTitle.title" defaultMessage="Results for" />
        );
    } else if (isFuzzyResults) {
        customHeader = (
            <FormattedMessage
                id="sb.SbSharedTitle.fuzzyResultsTitle"
                defaultMessage="We didn't find any results for"
            />
        );
    }

    const HeaderTitle = (): ReactElement => {
        if (pageType === SEARCH) {
            return (
                <span>
                    {customHeader || header}{' '}
                    <i className={dibsCss.sassyFontHeaderNoneItalic} data-tn="search-term">
                        "{searchTerm}"
                    </i>
                </span>
            );
        }
        if (pageType === DEALER) {
            if (isClient) {
                const shouldShowSellerName =
                    checkRewardedSeller(seller) ||
                    (!isSellerBrandingRemovalTestVariant() &&
                        checkTradeUserUnderTopLevelLoyaltyTiers(user || null));

                if (!shouldShowSellerName) {
                    return (
                        <FormattedMessage
                            id="sb.SbSharedTitle.hiddenSellerName"
                            defaultMessage="Vetted Professional Seller"
                        />
                    );
                }
            } else {
                return (
                    <span className={classnames(styles.shimmer, dibsCss.textTransparent)}>
                        {header}
                    </span>
                );
            }
        }
        return <>{header}</>;
    };

    const wrapperClass = classnames(dibsCss.flex, dibsCss.itemsCenter, dibsCss.m0, dibsCss.mtSmall);
    const headerWrapperClasses = classnames(wrapperClass, headerWrapperClass);
    const subHeaderWrapperClasses = classnames({ [wrapperClass]: isFuzzyResults });
    const subHeaderClass = classnames(styles.subHeader, {
        [styles.withFollowButton]: isFuzzyResults,
    });

    return (
        <div className={styles.container}>
            <div className={headerWrapperClasses}>
                {/*
                    Wrapped in fragment container to avoid:
                    This JSX tag's 'children' prop expects a single child of type 'ReactNode', but multiple children were provided.
                */}
                <>
                    <HeadingTag
                        className={classnames(styles.header, dibsCss.m0)}
                        data-tn="header-title"
                    >
                        <HeaderTitle />
                    </HeadingTag>
                    {showTitleFollowButton && children}
                </>
            </div>
            {subHeader && Heading && !(isMobile && isFuzzyResults) && (
                <div className={subHeaderWrapperClasses}>
                    <>
                        <Heading className={subHeaderClass} data-tn="header-sub-title">
                            {subHeader}
                        </Heading>
                        {isFuzzyResults && children}
                    </>
                </div>
            )}
            {isFuzzyResults && !soldItems?.totalResults && !!totalResults && (
                <div className={styles.fewerResultsHeader}>
                    <FormattedMessage
                        id="sb.SbSharedTitle.matchingFewerWordsTitle"
                        defaultMessage="Showing results matching fewer words"
                    />
                </div>
            )}
        </div>
    );
};
